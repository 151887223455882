import { useForm } from 'react-hook-form'

export default function Newsletter() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm()

  const onSubmitHandler = (data) => {
    // console.log({ data })
    reset()
  }
  return (
    <section className='my-12 lg:px-20 xl:px-40 md:px-20 px-4 newsletter py-20 flex flex-col justify-center items-center'>
      <h2 className='text-center text-2xl text-white uppercase'>
        Sign up for our Newsletter
      </h2>
      <p className='text-center text-base lg:text-xl my-6 lg:my-10 text-white md:w-3/5 '>
        Sign up to recieve email updates on new routes, discounts, special
        promotions and more.
      </p>

      <form
        className='border border-solid border-primary justify-between items-center w-full md:w-3/5 h-12 lg:h-14 py-1 bg-transparent flex flex-row rounded-lg px-2 z-10 bg-white text-primary'
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <label htmlFor='email' />
        <input
          name='email'
          className='w-full h-full bg-transparent mx-2 focus:outline-none focus:ring-0 focus-visible:ring-transparent focus-visible:ring-0 focus-visible:outline-none shadow-none border-none focus-visible:ring-transparent text-xs lg:text-sm'
          type='email'
          placeholder='Email address'
          {...register('email')}
          required
        />
        <button
          type='submit'
          className='bg-mainColor h-full w-45 md:w-2/5 rounded-lg items-center justify-center capitalize text-white text-xs lg:text-sm font-regular hover:bg-hover'
        >
          subscribe
        </button>
      </form>
      <p className='text-red-700 text-sm my-1 transition duration-500 ease-in-out'>
        {errors.email?.message}
      </p>
    </section>
  )
}
